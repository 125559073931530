

 
.leftPanel__container {

  &.-iconOnly {
    .sideBar {
      // -ms-overflow-style: none; 
      // scrollbar-width: none; 

      // &::-webkit-scrollbar {
      //   display: none; 
      // }

      // width: 55px;

      .label.-top {
        transition: none;
        opacity: 0;
        max-height: 50px;
      }
      .list__itemText {
        opacity: 0!important;
      }

      &.-subBar{
        // margin-left: 55px;
        // width: 275px;
      }

      &.-small {
        width: 40px;

        &.-subBar{
          width: 324px;
          margin-left: 40px;
        }
      }
    }  
  }

}

.-sublist {
  margin: 0 5px;
}
/**
 * Sidebar label styling
 **/
 .sideBar__list.-details {
   margin-top: 40px!important;
  z-index: 4;
  position: fixed;
  background-color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.087);
  margin-bottom: -2px;
  padding-bottom: 5px;;
}
.sideBar__list  {
  // margin-top: 45px;
    z-index: 4;
  .MuiListItemText-root {
    transition: all ease-in-out 0.3s;
  }
  .label {
    &.-top,
    &.-large,
    &.-blank {
      width: 100%;
      padding: 10px 0;
      display: block;
    }
    &.-title {
      padding-top: 15px;
    }
    &.-title,
    &.-top {
      overflow: hidden;
      max-height: 45px;
      font-size: 14px;
    }
    &.-top {
      // background: $gray-110; 
      // border-bottom: $gray-210 2px solid;
      width: 100%;
    }
    &.-blank {
      margin-bottom: 10px;
    }
    &.-large {
      font-size: 18px;
    }
    .-sub {
      font-size: 12px;
      text-transform: capitalize;

      svg {
        margin: -2.5px 2.5px 0 0;
      }
    }
  }
  
}


// .sideBar__items .sideBarItem,
// .sideBar__buttons .sideBarButton {
//   transition: all ease-in-out 0.3s;
//   text-align: center;
//   display: inline-block;
//   border: 2px solid $gray-210;
//   padding: 0;
//   margin: 5px;

//   &.-active {
//     color: $sky-blue;
//     background-color: $gray-120;
//   }
//   &:hover {
//     background-color: $gray-120;
//   }
// }
.set {
  float: right;
  padding: 5px 2.5px;
  margin-top: -2.5px;
  transition: all ease-in-out 0.2s;

  svg {
    transition: all ease-in-out 0.2s;
    transform:scale(1);
    fill: $sky-blue;  
  }
  &.-secondary {
    padding-right: 5px;
    svg {
      fill: $storm;
    }
  }
  &.-disabled {
    opacity: 0.3;
  }
  
  &:hover {
    svg {
      transform:scale(1.25);
      fill: darken($sky-blue, 15%);
    }
    cursor: pointer;
  }
  
}
