




.control-border-radius-panel-outer {
	display: flex;
	height: 50px;
	width: 104px;
	.border-radius-panel-inner {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		box-sizing: border-box;
		height: 50px;
		width: 104px;
		border: 1px solid darken($gray-210, 25%);
		background-color: $gray-110;
		font-size: 12px;
		transition: all ease-in-out 0.3s;
	}
}



// .component::after {
// 	position: absolute;
// 	content: '';
// 	display: block;
// 	right:0;
// 	margin-right:-10px;
// 	border: none;
// 	pointer-events: none;
// 	border-top: 1px dashed $sky-blue;
// 	width: 10px;
// }



.control-docking-panel-outer {
	display: flex;
	height: 100px;
	width: 104px;
	.docking-panel-inner {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		box-sizing: border-box;
		height: 100px;
		width: 104px;
		border-radius: 6px;
		border: 1px solid $gray-210;
		background-color: $gray-110;

		.docking-rail {
			display: flex;
			position: relative;
			height: 8px;
			width: 100%;
		}
		.docking-rail.left {
			flex-direction: row-reverse;
		}
		.docking-direction {
			.docking-point {
				width: 8px;
				height: 8px;
				background-color: $light-blue;
				border: 1px solid $sky-blue;
				border-radius: 50%;
				position: absolute;
				cursor: pointer;
				align-items: center;
				justify-content: center;
				&:hover {
					background-color: lighten($sky-blue, 20%);
				}
			}
			display: flex;
			.docking-line {
				visibility: hidden;
				position: absolute;
				border: none;
				pointer-events: none;
			}

			&.direction-docked {
				.docking-line {
					visibility: visible;
				}
			}
		}
		.docking-element {
			.docking-point {
				width: 8px;
				height: 8px;
				background-color: $light-blue;
				border: 1px solid $sky-blue;
				border-radius: 50%;
				position: absolute;
				cursor: pointer;
				align-items: center;
				justify-content: center;
				position: relative;
				&:hover {
					background-color: lighten($sky-blue, 20%);
				}
			}
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 24px;
			height: 24px;
			position: relative;
			z-index: 1;
		}
		.docking-direction.direction-docked {
			.docking-point {
				opacity: 1;
				background-color: $sky-blue;
			}
			.docking-line {
				visibility: visible;
			}
		}
		.docking-element.direction-docked {
			.docking-point {
				opacity: 1;
				background-color: $sky-blue;
			}
			.docking-line {
				visibility: visible;
			}
		}
		.docking-direction.direction-disabled {
			.docking-point {
				opacity: 1;
				border-color: #bebebe;
				background-color: #f3f3f3;
				cursor: default;
			}
		}
		.docking-element.direction-disabled {
			.docking-point {
				opacity: 1;
				border-color: #bebebe;
				background-color: #f3f3f3;
				cursor: default;
			}
		}
		.docking-direction.top {
			position: absolute;
			transition: width 0.3s ease, height 0.3s ease;
			justify-content: center;
			height: 18px;
			bottom: 61px;
			.docking-point {
				top: -6px;
				left: -4px;
			}
			.docking-line {
				border-right: 1px dashed $sky-blue;
				height: 100%;
			}
		}
		.docking-direction.top.direction-docked {
			transition: width 0.3s ease, height 0.3s ease;
			height: 37px;
		}
		.docking-direction.bottom {
			position: absolute;
			transition: width 0.3s ease, height 0.3s ease;
			justify-content: center;
			height: 18px;
			top: 61px;
			.docking-point {
				bottom: -6px;
				left: -5px;
			}
			.docking-line {
				border-right: 1px dashed $sky-blue;
				height: 100%;
			}
		}
		.docking-direction.bottom.direction-docked {
			transition: width 0.3s ease, height 0.3s ease;
			height: 37px;
		}
		.docking-direction.left {
			position: absolute;
			transition: width 0.3s ease, height 0.3s ease;
			align-items: center;
			width: 18px;
			height: 8px;
			.docking-point {
				left: -6px;
				bottom: 0;
			}
			.docking-line {
				border-top: 1px dashed $sky-blue;
				width: 100%;
			}
		}
		.docking-direction.left.direction-docked {
			transition: width 0.3s ease, height 0.3s ease;
			width: 100%;
		}
		.docking-direction.right {
			position: absolute;
			transition: width 0.3s ease, height 0.3s ease;
			align-items: center;
			width: 18px;
			height: 8px;
			.docking-point {
				right: -6px;
				bottom: 0;
			}
			.docking-line {
				border-top: 1px dashed $sky-blue;
				width: 100%;
			}
		}
		.docking-direction.right.direction-docked {
			transition: width 0.3s ease, height 0.3s ease;
			width: 100%;
		}
	}
}
.control-docking-panel-outer.is-disabled {
	.docking-panel-inner {
		background-color: #f3f3f3;
		.docking-direction {
			.docking-point {
				border-color: #bebebe;
				background-color: #f3f3f3;
				cursor: default;
			}
			.docking-line {
				border-color: #bebebe;
			}
		}
		.docking-element {
			.docking-point {
				border-color: #bebebe;
				background-color: #f3f3f3;
				cursor: default;
			}
		}
		.docking-direction.direction-docked {
			.docking-point {
				border-color: #bebebe;
				background-color: #bebebe;
			}
		}
		.docking-element.direction-docked {
			.docking-point {
				border-color: #bebebe;
				background-color: #bebebe;
			}
		}
	}
}
.composite-docking-control-with-label {
	.control-docking-panel-outer {
		padding: 1px;
		height: 100px;
		.docking-panel-inner {
			width: 104px;
			height: 100%;
		}
	}
}
.control-docking-plane-button-outer {
	&:not(.is-disabled) {
		.control-docking-plane-button {
			&:hover {
				top: -2px;
				bottom: -2px;
				left: -2px;
				right: -2px;
				.docking-point {
					background-color: #7cafff;
				}
			}
		}
		.control-docking-plane-button.plane-docked {
			.docking-point {
				background-color: $sky-blue;
				&:hover {
					background-color: $sky-blue;
				}
			}
		}
	}
	.control-docking-plane-button {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 0 5px #e2e3ea;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transition: top 0.2s ease, bottom 0.2s ease, left 0.2s ease, right 0.2s ease;
		.docking-point {
			position: relative;
		}
	}
	.control-docking-plane-button.is-disabled {
		.docking-point {
			opacity: 1;
			border-color: #bebebe;
			background-color: #f3f3f3;
			cursor: default;
			&:hover {
				background-color: #f3f3f3;
			}
		}
	}
	.control-docking-plane-popover {
		display: none;
		align-items: center;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 0 5px #e2e3ea;
		position: absolute;
		width: 28px;
		height: 28px;
		top: -2px;
		left: -2px;
		overflow: hidden;
		justify-content: center;
		transition: width 0.2s ease, height 0.2s ease, top 0.2s ease, left 0.2s ease;
		.docking-plane-spacer {
			flex: 1 0 0;
		}
		.docking-plane {
			opacity: 0;
			transition: opacity 0.2s linear;
		}
		.docking-plane.vertical {
			height: 24px;
			min-height: 24px;
			width: 24px;
			min-width: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			border-radius: 6px;
			background-color: #f8faff;
			border: 1px solid #c3daff;
			position: relative;
			.docking-point {
				position: relative;
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				border-right: 1px solid #c3daff;
				height: 100%;
				width: 50%;
			}
			&:not(.is-disabled) {
				&:hover {
					border-color: #7cafff;
					&:before {
						border-color: #7cafff;
					}
					.docking-point {
						background-color: #7cafff;
					}
				}
			}
		}
		.docking-plane.vertical.is-disabled {
			cursor: default;
			border: 1px solid #bebebe;
			.docking-point {
				opacity: 1;
				border-color: #bebebe;
				background-color: #f3f3f3;
				cursor: inherit;
			}
			&:before {
				border-right: 1px solid #bebebe;
			}
		}
		.docking-plane.vertical.plane-docked {
			.docking-point {
				background-color: $sky-blue;
			}
		}
		.docking-plane.horizontal {
			height: 24px;
			min-height: 24px;
			width: 24px;
			min-width: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			border-radius: 6px;
			background-color: #f8faff;
			border: 1px solid #c3daff;
			position: relative;
			.docking-point {
				position: relative;
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				border-bottom: 1px solid #c3daff;
				height: 50%;
				width: 100%;
			}
			&:not(.is-disabled) {
				&:hover {
					border-color: #7cafff;
					&:before {
						border-color: #7cafff;
					}
					.docking-point {
						background-color: #7cafff;
					}
				}
			}
		}
		.docking-plane.horizontal.is-disabled {
			cursor: default;
			border: 1px solid #bebebe;
			.docking-point {
				opacity: 1;
				border-color: #bebebe;
				background-color: #f3f3f3;
				cursor: inherit;
			}
			&:before {
				border-bottom: 1px solid #bebebe;
			}
		}
		.docking-plane.horizontal.plane-docked {
			.docking-point {
				background-color: $sky-blue;
			}
		}
	}
	.control-docking-plane-popover.shown {
		display: flex;
	}
	.control-docking-plane-popover.hover {
		overflow: visible;
		width: 72px;
		height: 40px;
		top: -8px;
		left: -24px;
		.docking-plane {
			opacity: 1;
		}
	}
}
.control-docking-plane-button-outer.is-disabled {
	.control-docking-plane-button {
		cursor: default;
	}
}

