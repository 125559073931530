
$inspectBorderActive: 1.5px solid $sky-blue;
$inspectBorderHover: .5px solid $sky-blue;
$inspectBorderFocus: .5px solid lighten($sky-blue, 15%);
$inspectTransition: all ease 0.45s;

$g-900: #40792E;
$g-600: #84B36A;
$g-300: #ffffff46; //#DCF1C5; dcf1c546
$g-100: #EDFADC;

$o-900: #bb7300;
$o-600: #ff9d00;
$o-300: #ffaf4e46; //#eeb252; eeb25246
$o-100: #f9d291;
.resizeWrapper {
    width: 100%;
    height: 100%; 
    position: relative;
    
    .react-draggable {
        transform: none!important;
        // position: relative!important;
    }
}
.inspectors .child {
    .changedContainer {
        pointer-events: none;
        position: relative;
        z-index: 5;
        overflow: visible;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;// y align
        justify-content: center;// x align
    }
}

/**
 * Changed label 
 **/
.inspectors  {
    .changedLabel {
        pointer-events: none;
        transform: translateY(-20px);
        min-width: 75px;
        font-size: 10px!important;
        font-weight: bold;
        padding: 2.5px 2px;
        color: #fff;
        text-transform: capitalize;
        white-space: nowrap;
        z-index: 99;
        position: relative;
        
    }
    .-bottom .changedContainer .changedLabel {
        transform: translateY(20px);
    }
    .-border {
         .changedLabel {
            margin-left: 5px;
            background-color: #067affe1;
        }
    }
    .padding {
        .changedLabel {
            background-color: #40792ee1;
        }
    }
    .margin,
    .docking {
        .changedLabel {
            background-color: #f79b06e1;
        }
    }
}

.inspectors .react-draggable {
    transform: none!important;
    // position: relative!important;
}





.builderWrapper .component.-active .inspectors.-active .-border, 
.inspectorWrapper .component.-active .inspectors.-active .-border
.builderWrapper,
.inspectorWrapper {
    .inspectors {
        opacity: 1;
        position: absolute;
        content: '';
        display: block;
        pointer-events: none;
        // background: red; 
        top:0;
        left:0;
        .handle {
            pointer-events: all;
            // transition: $inspectTransition;
        }
    }
    .repeater .inspectors .-border {
        border: 0.1px solid lighten($sky-blue, 20%);
    }
    .row .inspectors .-border {
        border: 0.1px dashed $gray-210;
    }
    .column .inspectors .-border {
        border: 0.2px dashed $storm;
    }
    .-stack .inspectors .-border {
        border: 0.2px dotted dashed lighten($storm, 5%);
    }
    .component .inspectors .-border {
        border: .5px dotted $gray-310;
    }
    
    .repeater {
        .inspectors {
            bottom:0 !important;
            right:0 !important;
        }
    }
    @each $element in $elements {
        .-#{$element},
        .#{$element} {
            .inspectorLive.padding {
                // right:0;
                // bottom:0;
            }
            &.-hover .inspectors.-hover  {
                opacity: 1;
                .-border {
                    border: $inspectBorderHover;
                }
            }
            &.-active .inspectors.-active {
                opacity: 1;
                .-border {
                    border: $inspectBorderActive;  
                }
            } 
            &.-hover .inspectors.-hover  .inspectorLive:is(.margin, .docking) {
                // display: none;
            }
            &.-hover:focus,
            &.-hover:active {
                .inspectorLive  {
                    opacity: 0.6;

                    .-border {
                        border: $inspectBorderFocus;
                    }
                }
            }
        }
    }
    
    @each $element in $elements {
        .#{$element} {
            &.-hover .-devLabel.-#{$element} {opacity: 0.6!important;}
            &.-active .inspectors.-active .inspectorLive.-#{$element} {
                opacity: 1;
                .child { opacity: 1; }
            }
        }
    }
   
}




// .border__container,
.inspector__pos,
.inspector__menu {
    // transition: inset 0.2s cubic-bezier(0, .55, .79, .63);
    transform-origin: center center;
}

.inspectorLive,
.inspectorLive .child {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    content: '';
    display: block;
    padding: 0;
    margin: 0;
    border: none;
}
// .inspectorLive {
.inspector.inspect {
    $margin-border: 1.5px dashed rgba(247, 154, 6, 1);
    
    //Child rnd editors
    .child-old {
        transform: none!important;
        .handle::after {
            z-index: 4;
            transform-origin: center center;
            // transition: all ease-in 0.2s;
        }
        &.-right {
            right:0;
            left: auto !important;
        }
        &.-bottom {
            bottom:0;
            top: auto !important;
        }  
        &::before {
            position: absolute;
            content: '';
            display: block;
            opacity: 0;
        }
        &.-hover::before {
            opacity: 1;
        }
        &.-zero {
            &.-right, &.-left {
                width: $handleBorderWidth;
                height: 100%!important;
            }
            &.-top, &.-bottom {
                width: 100%!important;
                height: $handleBorderWidth;
            }
        }
    }
    &.padding-old .child.-zero {
        &.-right, &.-left {
            width: 4px ;
            height: 100%!important;
        }
        &.-top, &.-bottom {
            width: 100%!important;
            height: 4px ;
        }
    }

  
    .margin-old .child.-zero,
    .docking-old .child.-zero {
        &.-right {margin-right: -#{$handleBorderWidth} !important;} 
        &.-left {margin-left: -#{$handleBorderWidth} !important;}
        &.-top {margin-top: -#{$handleBorderWidth} !important;}
        &.-bottom {margin-bottom: -#{$handleBorderWidth} !important;}
    }
    // &.padding .child.-zero {
    //     &.-right {margin-right: #{$handleBorderWidth} !important;} 
    //     &.-left {margin-left: #{$handleBorderWidth} !important;}
    //     &.-top {margin-top: #{$handleBorderWidth} !important;}
    //     &.-bottom {margin-bottom: #{$handleBorderWidth} !important;}
    // }
    .margin-old .child,
    .docking-old .child,
    .padding-old .child {
        &::before {
            // transition: all ease-in-out 0.2s;
        }
        &.-vertical {
            .docking-end { 
                position: absolute;
                bottom:0;
                margin: 0 0 0 -10px;
            }
            &::before {
                top: calc(50% - 0.75px);
                width: 100%;
                border-top: $margin-border; 
            }
        }
        &.-horizontal {
            .docking-end {
                margin: -10px 0 0 0;
            }
            &::before {
                left: calc(50% - 0.75px);
                height: 100%;
                border-right: $margin-border; 
            }
        }
    }
    .margin-old,
    .docking-old {
        .child {
            .handle::after {
                border-color: rgba(253, 156, 0, 0.1);
                background: $o-300;
                
                &:hover {
                    background: $o-600;
                }
            }
            //Handle selectors
            .handle:hover,
            .handle.-active {
                &::after { //handle
                    background: $o-600;
                }
                &::before { //Border
                    background: $o-600;
                }
            }
            &.-active {
            background: rgba(255, 157, 0, 0.128);
                .handle::after {
                    background: $g-600;
                }
            }
        }
    }
// .component.inspector.inspect {
    .padding {
        // pointer-events: all;
        // cursor: pointer !important;
        // opacity: 0;
        // background: rgba(97, 164, 98, 0.07);
        // z-index: 0;
        // .handle {
        //     z-index: 10;
        //     &::before { //Border
        //         // z-index: 5;
        //         background: $g-600;
        //     }
        //     &::after {
        //         border-color: rgba(97, 164, 98, 0.1);
        //         background: $g-300;
                
        //         &:hover {
        //             background: $g-600;
        //         }
        //     }    
        // }

        // &.-vertical::before{
        //     border-top-color: $g-900; 
        // }
        // &.-horizontal::before{
        //     border-right-color: $g-900; 
        // }

        // &.-active {
        //     background: rgba(97, 164, 98, 0.14);
        //     .handle{
        //         &::before {
        //             opacity: 0.5;;
        //         }
        //         &::after {
        //             background: $g-600;
        //         }
        //     }
        // }

        // transition: border ease-in-out 0.2s;
        $padding-border : 0.5px solid rgb(5, 127, 5);

        &::after {
            content: '';
            display: block;
            top:0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 9;
        }
        //Side selectors
        // &.-right .handle::before {transform: translateX(-1px);}
        // &.-left .handle::before {transform: translateX(1px);}
        // &.-top .handle::before {transform: translateY(1px);}
        // &.-bottom .handle::before {transform: translateY(-1px);}

        //Handle selectors
        // .handle:hover {
        //     &::after { //handle
        //         background: $g-600;
        //     }
        //     &::before { //Border
        //         opacity: 1;
        //     }
        // }

    }
    // .margin .child {
    //     position: absolute;
    //      pointer-events: all;
    //     background: darken(rgba(247, 154, 6, 0.062), 10%);
    //      transform-origin: left;
    // }
    // .docking .child {
    //     position: absolute;
    //      pointer-events: all;
    //     background: darken(rgba(247, 154, 6, 0.062), 10%);
    //      transform-origin: left;
    // }
}


















.-clone .inspectorLive {
    &.padding .child {
        background: darken(rgba(247, 154, 6, 0.062), 10%)!important; 
    }
}

  //Active inspector
.inspectorLive.-active  {
    &.-line {
      opacity: 1!important;
    }
    &.-border {
      border: .5px solid lighten($sky-blue, 15%);
      border: $inspectBorderActive;  
      border: .5px solid red;
    }
  }

.builderWrapper,
.inspectorWrapper {
  // Row @deprecated (old
  &:not(.-isDragging) {
    // .row {
    //   &.-hover {
    //     &:hover::after {
    //       border: $inspectBorderHover;
    //     }
    //     &:active::after,
    //     &:focus::after {
    //       border: $inspectBorderFocus;
    //     }
    //   }
    //   // &.-active::after {
    //   //   border: $inspectBorderActive !important;
    //   // }  
    // }

    //Hover state
    @each $element in $elements {
      .#{$element} {
        &.-hover .inspectors.-hover  {
            opacity: 1;
            .-border {
                border: $inspectBorderHover;
            }
        }
        &.-active .inspectors.-active {
            opacity: 1;
            // .inspectorLive
            .-border {
                border: $inspectBorderActive!important;
            }
            
        }
    
        &.-clone {
            &.-active {
                // opacity: 0.7!important;
                
                .inspectors.-active.-clone .-border  {
                    border: 1.5px solid rgba(247, 154, 6, 0.682)!important; 
                }
                &.-hover {
                    opacity: 1!important;
                }
            }
            &.-cloneActive {
                opacity: 0.8!important;
                .inspectors.-cloneActive.-clone .-border {
                    border: .5px dashed rgba(247, 154, 6, 0.682)!important; 
                }
            }
            &.-contextHover {
                .inspectors.-clone.-contextHover .-border {
                    // border: .5px dashed rgba(247, 154, 6, 0.456)!important;  
                }
            }
            &.-hover {
                // opacity: 1!important;

                .inspectors.-clone.-hover .-border {
                    border: 1.5px solid rgba(247, 154, 6, 0.656)!important;  
                }
            }
        }
       
        &.-hover:focus .inspectorLive,
        &.-hover:active .inspectorLive  {
            opacity: 0.6;
            .-border {
                border: $inspectBorderFocus;
            }
        }
      }

    }
    .repeater {
        .inspectors .-border {
            border-color: #f79b06;
        }
        &.-hover .inspectors.-hover  {
            opacity: 1;
            .-border {
                border: .5px dashed #f79b06;
            }
        }
        &.-active .inspectors.-active {
            .-border {
                border: 1.5px solid #f79b06 !important;
            }
        }
    }
    
      
  }
}