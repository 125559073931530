$activeInputBorder: 1px solid $sky-blue;
$activeInputColor: $sky-blue;

/**
Global menu items
mui list item
**/
.menuInput {
  margin: 5px 0 15px;
  width: 100%;
  fieldset {
    border: none;
    transition: 0.3s ease-in-out all;
  }
  &:hover fieldset{
    border: 1px solid $gray-210;
  }
  .Mui-focused {
    fieldset{
      border: $activeInputBorder!important;
    }
  }
  &.-active fieldset,
  &.-active:hover fieldset {
    border: $activeInputBorder;
    border-width: 2px;
  }
}
.control__panel .menuInput {
  margin: 0;
}



/**
Variants control
**/
.variantsContainer {
  // min-width: 175px;
  // top: -40px;
  // position: absolute;
  // left: 0;
  &.-selector {
    .MuiList-root {
      margin: 0;
    }
    .menuItem.-unset {
      opacity: 1;
    }
    .variantGroup:not(.-layouts),
    .MuiDivider-root.MuiDivider-fullWidth {
      display: none;
    }
  }
 
}
.menuItem {
  transition: 0.15s all ease-in-out;
  border: 1px solid rgba(0,0,0,0);

  &.header {
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
    
    &:hover {
      cursor:default;
      background: none;

      :is(svg, button):hover {
        cursor: pointer;
      }
    }
  }
  .-icon {
    overflow: hidden;
    text-transform: uppercase;

    svg,
    path {
      max-height: 15px;
      max-width: 15px;
    }
    svg path{
      transform: scale(0.6);
      transform-origin: top left;
    }
  }

  &.actionTrigger {
    svg.right {
      position: absolute;
      right: 5px;
    }
  }

  &.-current {
    border: 1px dashed $activeInputColor;
    .-icon :is(svg, path) {fill: $activeInputColor;}
    &:hover{
      background: $gray-120;
    }
  }
  &.-preview {
    border: 1px solid $green-shamrock;
    .-icon :is(svg, path) {fill: $green-shamrock;}
  }
  &.-currentAndPreview {
    border: $activeInputBorder;
    .-icon :is(svg, path) {fill: $activeInputColor;}
    &:hover{
      background: $gray-120;
    }
  }
  &.-unset {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
  &.-unfocus {
    opacity: 0.4;
    filter: blur(1px);
    border: none;

    &:hover {
      background: none;
      // opacity: 1;
      // filter: blur(0);
    }
  }
}

// .coreButtons,
// button.coreButton {
//   button {
//     margin: 10px 10px 0 0;
//   }
//   &.-text:not(.-small) button{
//       min-width: 90px;
//       max-width: 90px;
//       padding: 7.5px 10px;
//     }
//     &.-text button {
//       max-height: 35px;
//       text-transform: capitalize;
//       color: $storm;
//       font-weight: 600;
//       font-size: 12px!important;


//       &:hover,
//       &.-active {
//         color: $sky-blue;
//       }

//       &.-active {
//         font-weight: bold;
//       }
//     }

//     &.-rotate {
//       button svg {
//         transform: rotate(-90deg);
//       }

//       &.-rPos {
//         button svg {
//           transform: rotate(90deg);
//         }  
//       }
//     }

// }
// .coreButton {
//     // display: flex;
//     font-size: 14px;
//     line-height: 20px;
//     padding: 10px 2px;
//     text-transform: capitalize;
    
//     svg {
//       margin-right: 5px;
//       transition: all ease-in-out 0.2s;
//       transform: scale(1);
//       fill: $sky-blue;
//       color: $sky-blue;
//     }
//     &:hover {
//       cursor: pointer;

//       svg {
//         transform: scale(1.2);
//       }
//     }
//     &.-active {
//       color: $sky-blue;
//       svg {
//         fill: $sky-blue !important;
//         color: $sky-blue !important;
//       }
//     }
//     // todo add 
//     &.-icon {
//       font-weight: bold;
//     }
//     &.-notSet {
//       opacity: 0.5;
//       &:hover {
//         opacity: 0.8;
//       }
//       &.-active {
        
//       }
//     }
//     &.-disabled {
//       svg {
//         fill: $storm;
//         color: $storm;
//       }
//     }
//     &.-moveUp {
//       margin-top: -15px;
//     }
// }
// .buttonGroup {
//   display: flex;
//   justify-content: space-between;
//   padding: 0 24px;
//   margin: 5px 0;

//   &.-compact {
//     // margin: 0 0 10px;
//   }
  
//   .buttonGroup__element {
//     display: flex;
//     font-size: 14px;
//     line-height: 20px;
//     padding: 10px 2px;

//     svg {
//       transition: all ease-in-out 0.2s;
//       transform: scale(1);
//       fill: $sky-blue;
//       color: $sky-blue;
//     }

//     &:hover {
//       cursor: pointer;

//       svg {
//         transform: scale(1.3);
//       }
//     }
//     &.-active {
//       color: $sky-blue;
//       svg {
//         fill: $sky-blue !important;
//         color: $sky-blue !important;
//       }
//     }
//     // todo add 
//     &.-icon {
//       font-weight: bold;
//     }
//     &.-notSet {
//       opacity: 0.5;
//       &:hover {
//         opacity: 0.8;
//       }
//       &.-active {
        
//       }
//     }
//     &.-disabled {
//       svg {
//         fill: $storm;
//         color: $storm;
//       }
//     }
//   }
// }
.grid__controls .grid__control {
  button,
   button span{
    width: 40px;
    padding: 0;
    min-width: 40px;
  }
}
.buttonGroup.-textGroup,
.grid__controls .grid__control .buttonGroup {
      padding: 0;
      // margin: 5px 0;
      // margin-top: 5px;
      // margin: 5px 0;
      // margin-bottom: 10px; 
      float: left;
    }
    .group {
      border: solid 1px rgba(0,0,0,0);

      &.-active {
        border-color: $sky-blue;
      }
    }
    button {

      // max-width: 42px;
      border: 1px solid $gray-210;
    }

    //  margin: 0 0  0;
    //  transform: scale(0.8);
    .MuiButtonGroup-root {
      // display: inline-block;
   
      // transform: scale(0.9);
    

      .buttonGroup__element {
        color: $storm;
        font-size: 12px;
        transition: all ease-in-out 0.3s;
        svg {
          transition: all ease-in-out 0.3s;
          width: 24px;
          fill: $storm;
          color: $storm;
        }
        &.-icon {
          padding: 0;
        }
        &.-icon.-scale {
          svg {
            transform: scale(0.7)
          }
        }
        &.-noValue {
          color: $gray-210;
        }
        &.-maxW {
          width: 65px;
          max-width: 65px;
        }
        &.-preview {
          width: 45px;
          max-width: 45px;
        }
        &:hover,
        &.-active {
          color: $sky-blue;
          svg {
            fill: $sky-blue;
            color: $sky-blue;
          }
        }
        &.-active:not(.-current) {
          opacity: 1;
          animation: blinking 1s infinite alternate;
        }
        &.-active.-notSet {
          opacity: 0.7!important;
          // animation: blinking 1s infinite alternate;
        }
        &.-current {

          &.-notSet {
            // opacity: 1;
            animation: scaling 2s infinite alternate;
          }
          background-color: $gray-120;
          border-color: lighten($storm, 20%);
          // color: #000;
          font-weight: bold;

          svg :is(g, rect, path){
            stroke: $sky-blue;
            color: $sky-blue;
          }

          &:hover {
            // animation: none;
          }
        }
      }
    }
    .-clear {
      margin: 5px 0 0 0;
      transform: scale(0.7);
      height: 35px;
      width: 35px;
      min-width: 35px;

      padding: 2px 0 ;
      // position: absolute;
      float: left;
      right: 0;

}
.-scaling {
  animation: scaling 2s infinite alternate;
}
@keyframes blinking {
  0% {
      border-color: lighten($storm, 20%);
  }
  100% {
      border-color: lighten($sky-blue, 20%);
  }
}
@keyframes scaling {
  0% {
      -webkit-transform: scale(0.9);
      border-color: $storm;
      background-color: lighten($gray-120, 10%);
  }
  0% {
      -webkit-transform: scale(0.9);
      border-color: $storm;
      background-color: $gray-120;
  }
  100% {
      -webkit-transform: scale(1);
      background-color: $gray-120;
      border-color: lighten($storm, 20%);
  }
}

.ButtonGroup__container.-inline {
  .MuiButtonGroup-root {
    display: inline-block;

    &.-first {
      margin-right: 10px;
    }
  }
}
.MuiButtonGroup-root {
  margin: 0 0 15px;
  min-height: 35px;
  max-height: 35px;
  // width: 100%;
  // min-width: 240px;
  max-width: 240px;

  &.-noBorder {
    button {
      border: 1px solid $gray-210;
      padding: 7.5px 15px;
      
      svg {
        transition: all ease-in-out 0.3s;
      }
      svg :is(g, rect, path) {
        stroke: $storm;
        fill: $storm;
      }
      &:hover,
      &.-active {
        svg {
          transform: scale(1.1);

          :is(g, rect, path) {
            stroke: $sky-blue;
            fill: $sky-blue;
          }
        }
        color: $sky-blue;
      }
    }
  
    &.-text:not(.-small) button{
      min-width: 90px;
      max-width: 90px;
      padding: 7.5px 10px;
    }
    &.-text button {
      max-height: 35px;
      text-transform: capitalize;
      color: $storm;
      font-weight: medium;
      font-size: 12px;

      &:hover,
      &.-active {
        color: $sky-blue;
      }

      &.-active {
        font-weight: bold;
      }
    }

    .-selectButton {
      padding: 0!important;

        .MuiFormControl-root {
          border-left: none!important;
        }
        .MuiOutlinedInput-root.MuiInputBase-root input {
          border: none!important;
        }
    }

    &.-rotate {
      button svg {
        transform: rotate(-90deg);
      }

      &.-rPos {
        button svg {
          transform: rotate(90deg);
        }  
      }
    }
  }
}
.MuiButtonGroup-root.-noBorder.-stateSelector {
  button.-active {
    border-color: $green-shamrock!important;
    color: $green-shamrock;

    span {
      color: $green-shamrock;
    }
    svg :is(g, rect, path) {
      stroke: $green-shamrock;
    }
  }
}




//TODO move to alerts
.soo-alert {
  width: 100%;
  padding: 7.5px 15px;
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  border: dashed 0.5px rgb(212, 212, 212);
  color: rgb(37, 37, 37);
  background: #f4f4f4;

  &.-error {
    border-color:rgb(152, 120, 120);
    color: rgb(101, 22, 22);
    background: #fddbdb;  
  }

  &.-warning {
    border-color:rgb(152, 136, 120);
    color: rgb(101, 63, 22);
    background: #fdf1db;  
  }

  &.-success {
    border-color:rgb(92, 136, 87);
    color: rgb(39, 54, 37);
    background: rgb(172, 210, 168);  
  }
  
}
$inspectBorderActive: 1.5px solid $sky-blue;
//TODO create new 'dialog/ modal'   --> view styling

.MediaEditor {

  .tui-image-editor-container {
    background: none!important;
    margin: 0 auto;
    width: 100%!important;
    height: 100%!important;
    // max-width: 100%!important;
    // min-width: 100%;
  }
  .tui-image-editor-header-buttons {
    transform: scale(0.7);
    transform-origin: right center;
  }
  .tui-image-editor-header-logo {
    display: none!important;
  }
  .tui-image-editor-checkbox,
  .tui-image-editor-checkbox span,
  span, label {
    color: #212121 !important;
    color: #e5e5e5;
  }
  .tui-image-editor-controls {
    border-left: 1px solid #e7e9ed!important;
    // background: $gray-120!important;
    background: none!important;
    // background: $gray-210!important;
  }
}
.MediaGallery {



    // .MediaGallery__itemPreview {
    //   // padding: 10px;
    //   // background: #fff;
    //   box-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    //   // margin: -10px;
    //   transition: all ease-in-out 0.35s;
    //   // transform: scale(0.8);
    //   // top: 25%;
    //   // transform: scale(0.8);
    //   transform-origin: left top;
    //   position: absolute;
    //   opacity: 0;
    //   max-width: calc(24%);
    //   width: 200px;
    //   min-width: 200px;
    //   max-height: auto;
    // }

    // &:hover {
    //   // filter: blur(5px);

    //   .MediaGallery__itemPreview {
    //     opacity: 1;
    //     backdrop-filter: blur(2px);
    //     img {
    //       backdrop-filter: blur(5px);
    //       filter: blur(0px);
    //       // filter: grayscale(0%)!important;
    //     }
    //   }
    // }
  
}
.soo-DataList {
  min-height: 70vh;

  .MuiDataGrid-root {
    margin: 25px 0;
  }
  .MuiDataGrid-footerContainer {
    position: absolute;
    right: 0;
    bottom: 0;

    p {
      margin-bottom: 0;  
    }
  }
  .DataList-alerts {
    left: 25px; right: 25px;
    position: absolute;
    bottom: 50px;
  }
}



.accordionGroup {
  .MuiAccordion-root:last-child {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.04),0px 1px 3px 0px rgba(0,0,0,0.07);
  }
  .accordion__header {

    &.-notice:not(.Mui-expanded) {
      border-top: .5px dashed lighten($sky-blue, 10%);
      background-color: lighten(#3a9cf2, 40%);
      box-shadow: none;
    }
  }
  
}



.chips {
  width: 100%;
  margin: 10px 0 0;

  .chip {
    display: inline-block;
    padding: 2.5px 5px;
    border-radius: 2px;
    background: $gray-120;
    margin: 5px 2.5% 5px 0;
    transition: all ease-in-out 0.3s;

    &:hover {
      color: $sky-blue;
      cursor: pointer;
      box-shadow: 2px 2px 8px rgba(0,0,0,0.01);
      transform: scale(1.1);
    }

  }
}
.fieldExpander {
  transition: margin ease-in-out 0.4s, background-color ease-in-out 0.4s;
  width: 100%;
  background: $gray-120;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  height:14px; 
  margin: 15px auto -7.5px;
  text-align: center;

  svg {
    margin: -7.5px 0 5px 0;
  }
  &:hover {
    cursor: pointer;
    background-color: $gray-210;
  }
  &.-expanded {
    margin-top: 5px;

    svg {
      transform: rotate(-180deg);
    }
  }
}
.MuiTextField-root {
  &.-ninja {
    margin: 0 0 -35px;
      font-family: inherit;
      float: left;

    label:not(.Mui-focused, .MuiInputLabel-shrink),
    input {
      font-size: 1.25rem;
      line-height: 1.2;
      color: var(--color-headings);
      font-weight: bold;
      font-family: inherit;
    }
    fieldset {
      border: none;
    }
  }
}

.sideBar {
  .inputGroup {
    // padding: 0 8px;
  }
}

.accordionInset {
  padding: 4px 10px 8px 15px;
}
.inputGroup {
  .inputGroup__header {

    .panel__trigger {
      position: relative;
      right: 0;
      max-width: 15px;
      transform: scale(0.8);
      margin-top: -5px;

    }
  }
  .inputGroup__divider {
    // margin: 15px 0 5px;
    // width: 100%;
    // border-top: 1px solid $gray-210;
  }
  &.-inset {
    padding: 4px 10px 8px 15px;

    span {
      line-height: 14px!important;
    }
  }


  &.-four {
    .stateIndicator {
      top: calc(25% - 2px);
      left: -5px;
      transform: scale(0.9)
    }
  }
  // &:first-child {
  //   padding-top: 0;
  //   margin-top: 0;
  // }
  // .MuiTextField-root,
  &.-four {
    .inputGroup__item {
      width: 22.5%;
      margin: 20px 2.5% 0 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.-corners {
      svg {
          transform: rotate(-45deg)!important;
      }
  }
  .inputGroup__item {
    float:left;
    // width: 45%;
    // margin: 15px 5% 0 0;

    .scrollSelect {
      max-height: 20vh!important;
      overflow: scroll;
    }

    &:last-child {
      margin-bottom: 15px;
    }


    &.-full {
      // width: calc(100% - 5%);
    }

    &.-token {
      .MuiInputBase-root {
        background: $gray-120;
      }
      .inputGroup__metric {
        padding: 10px 0;
        margin: 0 -5px;
      }
      input {
        // font-size: 12px;
        // color: rgba(0,0,0,0);
        // padding: 8.5px 0 8.5px 14px;
        transition: all ease-in-out 0.2s;
        // padding: 2.5px 2.5px;
        //  margin: 6px 0 6px 7px;
        // border: 1px solid $sky-blue;
        
        max-width: 90%;
        border-radius: 4px;
        // font-size: 9px;
        text-transform: uppercase;

        &:hover {
          background: $gray-120;
          transform: scale(1.1);
        }
      }
    }
  }

  .item__bottom {
    cursor: pointer;
    text-align: center;
    svg {
      transition: all ease-in-out 0.3s;
      transform: scale(1.2);
    }
    &:hover {
      svg {
        stroke: $sky-blue;
        transform: scale(1.3);
      }
    } 
  }

  .MuiInputBase-input,
  .MuiInputLabel-root,
  .MuiInputBase-root,
  .MuiInputAdornment-root .MuiTypography-root,
  .MuiFormControlLabel-label {
    font-size: 11px;
    line-height: 16px;
  }
  .MuiTextField-root{
    &.-noValue {
      &:hover .inputGroup__metric{
        opacity: 1;
      }
      .inputGroup__metric,
      .MuiInputAdornment-root  {
        color: $gray-310;
      }
    }
    &.-value {
      fieldset {
        border-color: $gray-210 !important;
        legend {
          padding: 0 5px;
        }
      }
    }
    fieldset {
      transition: border-color 0.1s ease-in;
      border-color: $gray-120;
    }

    .Mui-disabled {
      // opacity: 0.8;
      fieldset {
        border-color: $gray-120 !important;
      }
    }
    &:hover fieldset{
      border-color: $gray-310 !important;
    }
  }
  &.-large {
    .MuiInputBase-input,
    .MuiInputLabel-root,
    .MuiInputBase-root,
    .MuiFormControlLabel-label,
    .MuiInputAdornment-root .MuiTypography-root  {
      font-size: 14px;
      line-height: 18px;
    }
    .MuiInputLabel-root {
      margin-bottom: -5px;
      font-size: 12px;
    }
  }
  .inputGroup__metric {
    margin-right: -2.5px;
    padding: 10px 0 10px 10px;
  }
  .MuiInputAdornment-root,
  .inputGroup__metric {
    cursor: pointer;

    // .MuiTypography-root{
      // cursor: pointer;
      color: $sky-blue;
    // }
    svg path {
      fill: $sky-blue;
    }

    &.-small {
      padding: 2px 0;
      margin-right: -10px;
      line-height: 10px;
      font-size: 10px;
      cursor: pointer;
      color: $sky-blue;
    }
  }

  legend span {
    padding-left: 0;
    padding-right: 0;
  }

  &.-small {
    .MuiInputBase-input {
      padding: 8.5px 5px;
    }
  }

  &::after {
    // content: '';
    clear: both;
    display: block;
  }
}
.inputGroup__item {
  position: relative;

  &.-active {
    color: $sky-blue;
  }


}
#font-picker-headings,
#font-picker,
div[id^=font-picker] {
  max-width: 100%;
  box-shadow: none;

  .dropdown-button {
    background: none;
    transition: all ease-in-out 0.3s;

    .dropdown-font-family {
      color: #212121;
      font-size: 14px;
      line-height: 18px;  
    }
    
    border: 1px solid lighten($storm, 20%);
    max-width: 100%;
    border-radius: 5px;

    &:hover {
      border-color: $sky-blue;
    }
  }

  ul {
    background: #fff;

    li button {

      &:hover {
        background: $gray-120;
      }
      &.active-font {
        color: $sky-blue;
        background: $gray-120;
      }
    }
  }

  &.expanded ul {
    box-shadow: 4px 4px 16px rgba(0,0,0,0.15);
  }
}


.MuiModal-root.MuiPopover-root.MuiMenu-root {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    background: none;
    box-shadow: none;
  }
}

.color__tab,
.control__panel {
  .sketch-picker,
  .chrome-picker {
    width: calc(100%) !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
    // margin-right: -0 !important;

    .flexbox-fix:nth-child(4) {
      div {
        height: auto!important;
        width: auto!important;
        margin: 1px!important;
      }
      div span div {
        padding: 12.5px;
        box-shadow: none!important;
        // margin: 0 1px 1px 0;
      }
    }
  }
}
.-hidePresets {
  .sketch-picker .flexbox-fix:nth-child(4)  {
    display: none!important;
  }
}
.color__tab {
  div, span, .twitter-picker {
      // box-shadow:none !important;
  }
  .twitter-picker {
      box-shadow: none !important;
      margin-left: -20px !important;
      transform: scale( 0.9);
  }
}
.typeBox,
.customPicker,
.colorShades {

  .editSquare, 
  .colorInput,
  .color {
    cursor: pointer!important;
    height: 28px;
    cursor: pointer;
    position: relative;
    outline: none!important;
    border-radius: 4px;
    margin: 0px 6px 0px 0px;
    display: inline-block;

    transition: all ease-in-out 0.3s;

    &.-active{
      transform: scale(1.01);
      border: 1.5px solid rgba(3, 3, 3, 0.496);
      box-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    }
    &.-no {
      transform: scale(0.9)
    }

    &:hover:not(.-edit) {
      box-shadow: 1px 1px 8px rgba(0,0,0,0.1);
      transform: scale(1);
    }
    &:active {
      box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
      transform: scale(0.95);
    }
  }
  &::after {
    clear: both;
    content: '';
    display: block;
  }
  .colorControls {
    margin: 35px 5px 0 0;
    float: right;
  }
}

.controlLabel,
.colorControl {
    transition: all ease-in-out 0.2s;
    padding: 5px;
    font-size:12px;
    color: $storm;


    svg {
      transition: all ease-in-out 0.2s;
      fill: #212121;
      margin: -2.5px 5px 0 0;
    }

    &.-right {
      float:right;
    }

    &:hover {
      cursor: pointer;
      color: $sky-blue!important;

      svg {
        fill: darken($sky-blue, 15%) !important;
      }
    }
  }
.colorShades {
  transition: all ease-in-out 0.4s;
  height: 100%;
  position: relative;
  .shade {
    display: block;
    margin: 0 0 7.5px;

     &::after {
      content: '';
      clear: both;
      display: block;
      width: 100%;
    }

    .label {
      margin: 5px 0;
    }

    &.-underlay {
      transition: all ease-in-out 0.2s;
      position: absolute;
      top:-17.5px;
      bottom:10px;

      .color {
        transform: scale(0.8);
        box-shadow: none!important;
        border: none;

        &.-main {
          background-color: $gray-120;
          color: $storm;
          
          max-height: none;
          height: 100%;
          font-size: 10px;
          text-align: center;
          transform: scale(1)!important;
        }

      }
    }
    .controls {
      transition: all ease-in-out 0.4s;
      overflow: hidden;
      max-height: 40px;
      height: 40px;
      // height: 100%;
      max-width: 40px;
      float:left;
      .editSquare {
        float: left;
      }
      &.-toggled {
        max-height: 100%;
        height: 190px;
      }
    }

    &.-editing {
      .shades {
        float:left;
        max-width: 85%;
        &::after {
          content: '';
          clear: both;
          display: block;
          width: 100%;
        }  
      }
    }

    &.-selector{
      margin: 0 0 2.5px;

      .label {
        font-size: 12px;
        margin: 2.5px 0;
      }
      .color {
        margin: 2px;
      }
    }

   
  }
  .color {
    height: 35px;
    width: 35px;
    padding: 0;
    transform: scale(0.8);

    &.-main{

      transform: scale(1.01);
      border: 1.5px solid rgba(3, 3, 3, 0.496);
      box-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    }
    
  }
  &.-shaded {
    .shade {
      .color:not(.-edit) {
        transform: scale(0.9);
        margin: -5.5px -2.5px -5px;
        border: none!important;
        // padding: 22.5px;
        height: 45px;
        width: 45px;
        border-radius: 0;
        &.-main {
          transform: scale(1.15);
        }
      }  
      &.-underlay {
        opacity: 0;
      }
    }
  }
  &.-palette{
    // transform: scale(1.2);
    // margin-top: 35px;
    // padding: 10px 5px;
    transform-origin: top left;
    .color {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.-shaded {
      .color {
        margin: -5px -2.5px -5px !important;
      }
    }
  }
  &:not(.-palette) .-underlay {
    .color.-main {
      margin-left: -8px;
    }
  }
}
.customPicker {
  // max-width: 250px;
  // margin-top: 10px;

  .color {
    width: 28px;
    
    // -webkit-box-shadow: rgb(235, 20, 76) 0px 0px 4px;
    // box-shadow: rgb(235, 20, 76) 0px 0px 4px;
  }
  .colorInput {
    width: 130px;
    appearance: none;
  }
  .-inline {
    display: inline-block;
    line-height: 20px;
    margin: 0 -4px;
    
    // margin: 2px 6px 1px;
    input {
      line-height: 25px;
      // margin: -10px 0 5px 0;
      // border: 1px solid $storm;
      // color: $storm!important;
      font-size: 14px;

      // padding: 2px;
    }
    &::after {
      clear: both;
      content: '';
      display: block;
    }
  }
}




.typeBox {
  max-width: 100%;
  overflow: hidden;
  margin: 25px 5px;
  position: relative;

  .display {
    margin: 15px 0 0 ;
  }
  .options {
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    position: absolute;
    right: 0;
    top: 0;
    width: 15%;
    max-height: 40px;
    text-align: right;
    // .-right {
    //   right: 0;
    //   position: absolute;
    // }

    &.-toggled {
      width: 60%;
    }
  }
  .label {
    position: relative;
    color: $storm!important;

    .sub {
      font-size: 12px;
      color: lighten($storm, 10%);
    }  
    .editSquare {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
}

.editSquare {
  height: 35px;
  width: 35px;
  padding: 0;
  transform: scale(0.8);


  transform: scale(0.7);
  margin: 0 15px 0 5px;
  // background: $gray-120;
  border: 1px solid $gray-210;
  transition: all ease-in-out 0.2s;
  &.-borderOn {
    border-color: $sky-blue;
  }

  &.-bounce {
    animation-name: bounceXs;
    animation-iteration-count: infinite;
    animation-duration: 3s;
  }

  svg {
    position: absolute;
    left: 8px;
    top: 8px;
    fill: $storm;
  }  

  &:hover {
    opacity: 1;
    transform: scale(0.9);
    background: $gray-120;
    svg {
      fill: $sky-blue;
    }
  }
}
@keyframes bounceXs {
  0%  {
    transform: scale(0.8);
  }
    
  50% {
    transform: scale(0.78);
  }   
     
  100% {
    transform: scale(0.8);
  }
}



.dropdownChips {
  max-width: 100%;
  position: relative;
  display: block;
  border: 1px solid $gray-210;
  flex-wrap: wrap;
  padding: 5px 2px;
  border-radius: 5px;
  .MuiChip-root {
    margin-bottom: 5px;
  }

  input, fieldset {
    padding: 5px 2px;
    border: none!important;
  }
}