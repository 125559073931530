

.pageContainer {
  transform-origin: top center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 10px;
}

$gridCount: 12;
$colWidth: calc(100% / $gridCount);

$sizes: 'xs', 'sm', 'md', 'lg', 'xl';


.row {
  .column {
    min-width: calc(100% / $gridCount);

    @for $gridWidth from 1 through $gridCount {
      // &.#{$gridWidth} {
        
      // }
    }
  }  

}
// todo --> implement media queries on scss gen
// ! will be for front end live mode
// @each $size in $sizes {
//   .scr-#{$size} {

//     .row .column {
//       background: red;
      
//       @for $gridCol from 1 through $gridCount {
//         &.#{$size}-#{$gridCol} {
//           width: calc($gridCol * $colWidth);
//         }
//       }
//     }
//   }
// }
$orange:#fba700;
.SnackbarContainer-root .SnackbarItem-wrappedRoot {
  .SnackbarItem-variantWarning {
    transition: all ease-in-out 0.2s;
    background-color: $gray-120;
    color: darken($orange, 20%);
    border: 2px solid $orange;

    &:hover {
    // background: lighten($orange, 50%);
    }
  }
  
  button {
    transition: all ease-in-out 0.3s;
    font-size: 12px;
    background: none;
    color: #444;
    border: 1px solid $gray-210;

    &:hover {
      background: $gray-210;
    }
  }
}
.SnackbarItem-contentRoot {

}


.builderPage {
  pointer-events: none;
}

.builderWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  &.-dndDisabled {
    .dropZone{
      display: none!important;
    }
  }
  .builderPage,
  .columns {
    width: 100%;
  }
  &.-move,
  &.-move .react-transform-wrapper,
  &.-isDragging .react-transform-wrapper
  // &.-isDragging :is(.component, .column, .row, .stack) 
  {
    cursor: move!important;
  }
  // &.-noPinch :is(.react-transform-wrapper, .react-transform-component) {
  //   pointer-events: none;
  // }
  .builderPage {

    // margin-top: 50px;
    // min-height: calc(100vh - 100px);
    // top: 50%;
    // left: 50%;
    // -webkit-transform: translateX(-50%) translateY(-50%);
    // transform: translateX(-50%) translateY(-50%);

   
  }
  .builderCanvas {
    
    position: relative!important;
    transform: translate(0, 0)!important;
    top: 0!important;
    bottom: 20px!important;
    left:0;
    right:0;
    margin: 0 auto!important;
    width: 100%;
    .page__content {
      width: 100%;
      
      &.empty {
        .dropZone.isLast.page__dropZone{
          width: 100%;
          height: 20vh;
        }
      }
    }
    // .page__content {
    //   position: relative!important;
    //   // transform: translate(0, 0)!important;
    // }
  }
  .page,
  .canvas {
    z-index: 2;
    pointer-events: all;
    touch-action: all;
    // overflow-y: scroll;
    // overflow-x: hidden;
    height: 100%;

    // &::-webkit-scrollbar {
    //   width: 0; //only hide the vertical scrollbar
    //   height: 0px; //only hide the horizontal scrollbar
    // }

    .repeater {
      width: 100%;
      position: relative;
    }
    transform-origin: top;
    // transition: all ease-in-out 0.2s;
    // max-width: 100%;
    width: 100%;
    // margin: 20px 0 0 220px;

    background: #fff;
    flex: 1 1 auto;
    // padding: 25px 0px;
    border: 1px solid rgb(236, 236, 236);
    position: relative;

    .breakpoint-markers {
      position: absolute;
      background: rgba(255, 255, 255, 0.4);
      top: -20px;
      width: 100%;
      padding: 20px 0;
      // margin: -10px 0;
      overflow: hidden;

    
      &.-xs {
        // .-next, 
        .marker {
          &.-active:not(.-arrows) {
            // display: none;
            opacity: 0;
            pointer-events: none;
          }
          &.-next:hover {
            // background: $gray-120;
            // transform: scaleX(0.9);
            width: calc(100% - 5px)!important;
            margin-left: 2.5px!important;
          }
          &.-arrows {
            width: 100%!important;
            margin-left: 0!important;
          }
          .arrow {
            &.-left {
              margin-left: 5px!important;
            }
            &.-right {
              margin-right: 20px!important;
            }
          }  
        }
      }

    }
    
  }
  .page__size {
    overflow: hidden;
    opacity: 0.8;
    display: flex;
    position: absolute;
    width: 100%;
    left: -20px;
    top: 10px;
    font-size: 12px;font-size: 14px;
    font-weight: 500;
    white-space: pre;
    line-height: 20px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    letter-spacing: 1.75px;

    .content {
      min-width: 300px;
      margin: 0 auto;
      
      line-height: 11px;
      margin-bottom: 6px;
      color: $storm;

      .label {
        font-size: 9px;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
      }
    }
    b {
      font-weight: 600;
      font-size: 12px;
    }
  
  }
}