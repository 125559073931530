.builderWrapper {
  .row {
    display: block;
    width: 100%;
    // border-radius: $border-radius-xs;
    transition: all ease-in-out 0.3s;
    // &::after {
    //   border: $selector-border solid rgba(0,0,0,0);
    // }

    margin-left: 0!important;


    // margin: 0;
    // padding: 0 0 10px;
    position: relative;
    // .row__id {
    //   position: absolute;
    // }
    .row__add {
      transform: scale(0.6);
      transform-origin: center bottom;
      position: absolute;
      margin-top: -10px;
      left: calc(50% - 30px);
      transition: all ease-in-out 0.3s;
      opacity: 0;
      text-align:center;
      // width: 100%;

      width: 60px;
      background: rgba(129, 129, 129, 0.176);
      // margin: -45px auto 45px;
      height: 0px;
      // margin:-35px auto 0;

      span.add {
        background: $sky-blue;
        color: #fff;
        height: 35px;
        width: 35px;
        line-height: 10px;
        border-radius: 50%;
        padding: 6px 8px;
        font-weight: bold;
        font-size: 14px;
      }
    }

    &.-hover {
      cursor: pointer;
      // opacity: 0.4;
    }
    &:hover {
      cursor: pointer;
      // border: $selector-border solid $sky-blue;
      // opacity: 0.4;

      .row__id {
        opacity: 0.8;
      }  
      .row__add {
        opacity: 0.2;

        &:hover {
          margin-top: -20px;
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    &.empty {
      .dropZone.isLast.horizontalDrag{
        width: 99%;
        height: 50px;
      }
    }

  }
}