$sky-blue: #1082e9;

$black: #000;
$font-family-base: Arial;

$font-size-base: 16px;

$font-weight-base: 300;
$line-height-base: 1.5;
$body-color: #212121;
$body-bg: #fff;

$headings-margin-bottom: 5px;
$paragraph-margin-bottom: 10px;


$dt-font-weight: 400;
$font-weight-bolder: 900;

$link-color: $sky-blue;

$link-decoration: none;
    
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: $link-decoration;

$font-family-monospace: 'Courier New';


$table-cell-padding: 5px 10px;
$table-caption-color: $sky-blue;
 
$table-th-font-weight: bold;
$label-margin-bottom: 5px;
$enable-pointer-cursor-for-buttons: 1;

