


.MuiTooltip-popper {
  div {
    // background: darken($storm, 10%);
    background: rgba(100, 104, 115, 0.982);
    color: $gray-110;
  }
}
//Scroll bar scrollbar
// body,
// div {
//   &::-webkit-scrollbar-track{
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//     border-radius: 10px;
//     background-color: #F5F5F5;
//   }

//   &::-webkit-scrollbar{
//     width: 6px;
//     background-color: #F5F5F5;
//   }

//   &::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
//     background-color: #555;
//   }

// }
.coreContainer {
  &.-sidebarXs {
    .pageWrapper {
      margin-left: 50px;
      width: calc(100% - 60px);
    }
  }
  .pageWrapper {
    margin-top: 30px;
    margin-left: 190px;
    width: calc(100% - 200px);

    &.-sideBarRight {
      width: calc(100% - 440px);
    }
  }
}






.MuiTabs-flexContainer .MuiTab-root {
  svg{
    color: $gray-710;;

    &.stroke {
      stroke: $gray-710;;
    }
  }  
  &:hover {
    svg {
      color: $storm;
      
      &.stroke {
        stroke: $storm;
      }  
    }
    
  }
  &.Mui-selected {
    svg {
      color: $sky-blue;

      &.stroke {
        stroke: $sky-blue;
      }
    }
  }
}
svg.stroke  {
  stroke: $gray-710;
}