
.layout__layers {
  .label {
    transition: all ease-in-out 0.15s;
    cursor: pointer;
    text-transform: capitalize;
    // color: #000;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    padding: 2.5px 0 5px 5px;
    border: 1px solid rgba(0,0,0,0);

    &:hover {
      color: darken($sky-blue, 20%);
    }
    &.-active {
      color: $sky-blue;
    }
    &.-hover {
      color: darken($sky-blue, 5%);
    }
    &.-clone {
      opacity: 0.7;
      border: .5px dashed rgba(247, 154, 6, 0.682) !important; 
      &.-active,
      &.-hover {
        opacity: 1;
      }
      &.-hover {
        color: darken(#f79b06, 20%)!important;
        // border: .5px solid rgba(247, 155, 6, 0) !important; 
      }
      &.-active {
        color: #f79b06!important;
        border: .5px solid rgba(247, 154, 6, 0.682) !important; 
      }
    }

    svg {
      margin: 0 5px 2.5px 0;
    }
  }
  
  ._0 {
    margin: 10px 0 0 0;
    padding-left: 5px!important;
    font-weight: bold;
  }
  ._1 {
    padding-left: 15px!important;
    font-weight: bold;
  }
  ._2 {
    padding-left: 25px!important;
  }
  ._3 {
    padding-left: 35px!important;
  }
  ._4 {
    padding-left: 45px!important;
  }
  ._5 {
    padding-left: 55px!important;
  }
  .dropZone {

    // margin: -12.5px 0;
    height: 5px;
    max-height: 5px;
    left: 0!important;
    right: 0;
    position: absolute!important;

    &.column__dropZone {
      margin-top: -5px;

    }
    &.row__dropZone.canDrop {

      border: dashed 1px rgba(34, 244, 19, 0.575)!important;
      // height: 10px !important;
      background-color: rgba(53, 244, 19, 0.43);
    }
    &.stack__dropZone.canDrop {

      border: dashed 1px rgba(237, 19, 244, 0.575)!important;
      // height: 10px !important;
      background-color: rgba(192, 19, 244, 0.43);
    }
    &.page__dropZone.canDrop {
      margin-top: 5px;
      border: dashed 1px rgba(244, 19, 83, 0.575)!important;
      // height: 10px !important;
      background-color: rgba(244, 19, 83, 0.43);
    }

    &.horizontalDrag {
        width: 100%;
    }
  }
  @each $element in $elements {
    .layer__#{$element} {
      // margin-bottom: 5px;
      // padding-left: 15px;
      // height: 35px;

      .#{$element}__label {
        background: none;
        
        div.label {
          overflow: hidden;
          // max-width: 90px;
          max-height: 30px;
        }

        margin: 0;
        line-height: 25px;
        // max-height: 30px; 
      }
      .#{$element}_moveControl {
        float: right;
        background: none;
        box-shadow: none;
        border: none;
        padding: 0;

        svg {
          transition: all ease-in-out 0.2s;
          fill: $storm;
        }

        &:hover {
          svg {
            fill: #000;
          }
        }
      }
    }
  }
  .-active {
    &.label {
      background: $gray-110;
      border: 1px solid lighten($sky-blue, 25%)!important;
      // opacity: 1;
    }
  }
}
