$handleOffset: 3px;
$handleHeight: 15px;
$handleWidth: 5px;
$handleBorderWidth: 3px;
.inspectors .showHandles .handle,
.inspectors .showHover .handle {
  display: block;
  opacity: 1;
}

.builderPage,
.inspectors {
  .handle {
    position: absolute;
    display: block;
    // display: none;

    &:not(::after){ //: :before,
      transform: scale(0.7);
    }
    &:hover {transform: scale(1);}
    &::after
    // ,&::before
     {
      // transition: all ease-in-out 0.2s;
      content: '';
      display: block;
      position: absolute;
    }

    &::after { //Handle
      width: 10px !important;
      height: 10px !important;
      background: #fff;
      border-radius: 20px;
      border: 1px solid #ccc;
      display: none!important;
      // box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
    }
    // &::before { //Border
    //     // z-index: 4;
    //   background: darken($sky-blue, 5%);
    //   opacity: 0;
    //   height: 100%;
    //   width: $handleBorderWidth;
    // }
    &:hover {
      &::after{ //: :before,
        opacity: 1;
      }
    }
  }

  .long-handle {
    &::before { //Border
      // transform: translate(2px, 0px);
    }
    &::after { //Handle
      height: $handleHeight !important;
      width: $handleWidth !important;
    } 
  }

  .long-handle-horizontal {
    // &::before { //Border
    //   z-index: 4;
    //   // transform: translate(0px, 2px);
    //   width: 100% !important;
    //   height: $handleBorderWidth !important;
    // }
    &::after { //Handle
      z-index: 2;
      // display: none
      height: $handleWidth !important;
      width: $handleHeight !important;
    }
  }

  $trl: 4;
  $translate: $trl+'px';
  $lTrl: $trl*2+'px';
  .right-handle.bottom-handle,
  .left-handle.bottom-handle,
  .right-handle.top-handle,
  .left-handle.top-handle {
    // &::before {
    //   display: none;
    //   content: none;
    // }
  }

  /**
   * corners
   **/
  .right-handle.bottom-handle::after {
    transform: translate(4px, 4px);
  }
  .right-handle.top-handle::after {
    transform: translate(4px, 4px);
  }

  .left-handle.bottom-handle::after {
    transform: translate(4px, 4px);
  }

  .left-handle.top-handle::after {
    transform: translate(4px, 4px);
  }

  
  /**
   * top bottom
   **/
  .long-handle-horizontal.bottom-handle::after,
  .long-handle-horizontal.top-handle::after {
    left: 50% !important;
  }
  .long-handle-horizontal.bottom-handle::after {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    transform: translate(-#{calc($handleHeight / 2)}, 0);
  }
  .long-handle-horizontal.top-handle::after {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    transform: translate(-#{calc($handleHeight / 2)}, -7px); 
  }

  /**
   * sides
   **/
  .long-handle.left-handle::after,
  .long-handle.right-handle::after {
    top: 50% !important;
  }
  .long-handle.left-handle::after {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transform: translate(-5px, -#{calc($handleHeight / 2)}); //#{$handleOffset}
  }
  .long-handle.right-handle::after {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    transform: translate(3px, -#{calc($handleHeight / 2)});
  }



  .borderHandles {
    z-index: 3;
    // .long-handle-horizontal.bottom-handle,
    // .long-handle-horizontal.top-handle {
    //   max-height: 2px;
    // }
    // .long-handle.left-handle,
    // .long-handle.right-handle {
    //   max-width: 2px;
    // }
    .handle::after {
      opacity: 0;
      background: #f9f8f8f2;
      border-color: $sky-blue;
    }

    .handle:hover::after {
      background: darken($sky-blue, 5%);
      border-color:darken($sky-blue, 5%);
      opacity: 1;
    }


    .handle {
      transform: scale(1);
      display: block;
      z-index: 3;
      pointer-events: all;
    }
    &:hover .handle {transform: scale(1);}
    .handle {
      &.long-handle-horizontal,
      &.long-handle {
        &::after {display: none;}
      }
    }
  }
  
}