@import "builder/header";
@import "builder/page";
@import "builder/row";
@import "builder/column";
@import "builder/component";
@import "builder/dropzone";
@import "builder/layers";

@import "builder/layout/layoutDocking";

@import "builder/layout/handles";
@import "builder/layout/inspector";





// .-padding .handle {
//   $pTrl: 4px;
//   .right-handle.bottom-handle {
//     transform: translate($pTrl, $pTrl);
//   }

//   .right-handle.top-handle {
//     transform: translate($pTrl, -$pTrl);
//   }

//   .left-handle.bottom-handle {
//     transform: translate(-$pTrl, $pTrl);
//   }

//   .left-handle.top-handle {
//     transform: translate(-$pTrl, -$pTrl);
//   }
// }









// .builderWrapper,
.sideBar,
.core__header:not(.navigationHeader )  {
  // margin-top: 40px;
}
.builderContainer .iconOnly__toggle {
  top: 42px!important;
}

.dsg-row {
  .row-updated {

  }

}

.component.-text {
  p:last-child {
    margin-bottom: 0;
  }
}
.row-deleted .dsg-cell {
  /* Red */
  background: #fff1f0;
}

.row-created .dsg-cell {
  /* Green */
  background: #f6ffed;
}

.row-updated .dsg-cell {
  /* Orange */
  background: #fff7e6;
}

.builderWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height:100%;


  .component {
    // position: relative;

  }
  &.-preview,
  &.-dndDisabled {
    .column,
    .component { 
      &:hover {
        cursor: pointer;
      }
    }
  }
  &.-dndDisabled {
    .dropZone{
      display: none;
    }
  }
  &.-dndEnabled {

    .row {
      // margin: -1px 0;
      margin-left: 0;
      margin-right: 0;
    }
    .dropZone{
      pointer-events: none;  
      // background: none;
      z-index: 1!important;
      border: none;
      margin: -12.5px calc(-1% - 0.25px); //Border width
      // margin: -12.5px -1%; //canvas fix
      transition: all ease-in-out 0.3s;

      &.page__dropZone {
        max-width: 95%;
        margin: -12.5px auto;
      }
    }

    .column {
      // transition: all ease-in-out 0.3s;
      // transition: max-width ease-in-out 0.4s;

      &.empty .column__dropZone.dropZone.isLast {
        margin: -12.5px -10% !important;
        height: 100%;
        min-height: 25px;
        max-height: 100%;
        margin-top: 0;
        position: absolute;
      }
    }
    .component {
      // width: auto;
      // display: inline-block;
      &::after {
        clear: both;
        display: block;
        content: '';
      }
    }
  }
  // &.-dndEnabled:not(.-isDragging) {
  //   .column__dropZone {
  //     margin: 12.5px calc(-1% - 0.25px);
  //     height:0; 
  //   }
  // }

  &.-disableRowColumnsDropZone {

    .row__dropZone {
      display: none!important;
    }
  }
}
.gridLayoutLinesContainer {
  position: absolute;
  top: 25px;
  z-index: -1;
  bottom:0;
  left: 0;
  right:0;
}
.gridLayoutLines {
  width: 100%;
  max-width: 100%;
  // position: absolute;
  top:0;
  bottom:0;
  z-index: -1;
  // width: calc(100% + (12 * 2))!important;
  // transform: scale(0.7)!important;
  .MuiGrid-item {
    width: calc(7.3% - 5px)!important;
    max-width: calc(8.3% - 5px)!important;
    margin: 0 2.5px;
    div {

      height: 100%;
      min-height: 100vh;
      width: 100%;
      min-width: 100%;
      // margin: 0 5px;
      background: rgba(241, 241, 241, 0.305);
    }
    
  }
}
.builderWrapper,
.layoutLayers {
  &.-isDragging {
    // background: red!important;
    .column {
      // transition: all ease-in-out 0.3s;
      // max-width: 25%!important;
    }
    .component {
      // width: auto;
      display: inline-block!important;
    }
    cursor: move !important;
    
    .component__label.-fly{
      opacity: 0;
      // display: none!important;
    }
  }
  // dropZone isLast column__dropZone
}

//Todo --> open modal after menu
// .MuiModal-root.MuiPopover-root,
// .MuiBackdrop-invisible {
//   pointer-events: none;

//   .MuiPaper-root {
//     pointer-events: all;
//   }
// }




.builderWrapper {
  &.-preview {

    &:not(.-isDragging) {
      .dropZone,
      .-devLabel {
        display: none;
      }  
    }
    &.-isDragging .row:first-child {
      margin-top: -40px;
    }
    
  }
  &.-preview,
  &.-cleanView {
    :is(.row, .column,.component) { 
      // padding: 0;

      .control__panel {
        width: auto;
      }
      .inspectors:not(.active) .-border{
        border-color: rgba(0,0,0,0);
      }
    }
  }
  &.-preview,
  &.-cleanView {
    @each $element in $elements {
      .#{$element}:not(:hover) {
        .inspectors .-border{
          border-color: rgba(0,0,0,0);
        }
      }
    } 
  }
  .-dragging {
    .inspectors{
      display: none;
    }
  }

  &.-preview {
    :is(.row, .column, .component)::after {
      border: none;
    }
   
    .row,
    .column,
    .component { 
      // padding: 0;

      .control__panel {
        width: auto;
      }
    }
    .sun-editor {
      background-color: none!important;
      background: none!important;
    }

    .-devLabel,
    .row__add {
      display: none;
    }
    
  }
  
  .-devLabel {
      opacity: 0.2!important;
      font-size: 12px!important;
      // padding: 5px!important;

      @each $element in $elements {
        &.-#{$element} {
          position: absolute;
          left:2.5px;
        }
      }
      // &.-row,
      // &.-column,
      // &.-stack {
      //   position: absolute;
      //   left:2.5px;
      // }
      &.-row {
        margin-top: -25px;
      }
      &.-column,
      &.-stack {
        margin-top: -12.5px;
      }
  }
  

  // .-active::after,
  .row::after {
    transition: border ease-in-out 0.25s;
    pointer-events: none;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    // top: 0;
    // left: 0;
      left: 50%;
      top: 50%;
    transform: translate(-50%, -50%);
  } 

}

.addItems__empty {
  position:relative;
  width: 100%;

  p {
    font-size: 14px;
    font-weight: 200;
  }
  button {
    margin-left: -50px; //fix 
    text-transform: capitalize;
    cursor: pointer;
    color: $sky_blue;
    font-size: 12px 
  }
}
.columns {
  // display: flex;
  // margin: 20px 0;

  // &.-live {
  //   margin: 0 10%;
  // }
}
.column__container {
  min-width: calc(100% / 12);
}
.columnContainer {
  display: flex;
}

.base {
  //todo validate base selector why?
  // padding: 0.5rem 1rem;
  // background-color: white;
  // cursor: move;
}
