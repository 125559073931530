
@import "_reboot-config";

@import "controls/theme-variables";

@import "bootstrap/mixins";
@import "_reboot";


$selector-border: 0.5px;
$elements: 'component', 'stack', 'column', 'row', 'repeater', 'screen';


@import "core/core";
@import "core/builder";
@import "core/sidebar";
@import "core/controlPanel";
@import "core/input";


.MuiTooltip-tooltip {
  background-color: rgba(51, 51, 51, 0.873)!important;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1)!important;
}



:root {
  //Tooltip
  --tooltip-text-color: white;
  --tooltip-background-color: #333;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
    --gray75: #F9FBFC !important;
    //Data grid 
    // --rdg-header-background-color:

}

span.label {
  font-size: 12px;
}

html, body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.mosanic_site {
  overflow: hidden;
  position: relative;
}
body {
  background:#F9FBFC;
}
body, * {
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
}

.rc-dialog-close {
  z-index: 9;
}

/**Data grid **/
.rdg-light {
  .rdg-header-row {
    background: #fff;
    
  }
  .rdg-row {
    background: transparent;
    &[aria-selected=true] {
      background: #eee;
    }
  }
  .rdg-row-even {
    // background: $gray-120;
  }
  .rdg-cell-frozen {
    box-shadow: none;
  }
  .rdg-cell {
    border: none;
    font-size: 12px;

    :is(h1, h2, h3, h4, h5, h6, b, i) {
      font-size: 12px!important;
      font-weight: 300!important;
    }
    
    &[aria-selected=true] {
      outline: 0.5px solid $sky-blue;
      // outline-offset: -0.5px;
    }
    .input_field {
      transform: scale(0.9);
      transform-origin: left top;
      margin-top: 2.5px;
      label {
        margin-top: -4px;
      }
      input {
        line-height: 14px;
      }
    }
  }
  .rdg-header-row .rdg-cell[aria-selected=true] {
    outline: none;
  }
  &.-filters {
    .rdg-header-row .rdg-cell {
      line-height: 33px;
    }
  }
  .rdg-checkbox {
    transform: scale(0.8);
    transform-origin: bottom;
  }
}


div [data-slate-editor] .hiddenFont {
  font-size: 0 !important;
}
blockquote {
  font-family: Georgia, serif;
  border-left: 4px solid $gray-210;
  background: $gray-120;
  padding: 2.5px 5px;
}
.rc-dialog-mask {
  z-index: 4;
}
.rc-dialog-wrap {
  z-index: 5;
}
:root {
  --toastify-toast-min-height: 40px;
}
.Toastify__toast-container--top-center {
  top: 10px;
}
.Toastify__toast {
  margin-bottom: 12px;
  color: #212121;
}
.Toastify__toast-icon {
  transform: scale(0.8);
  margin-right: 8px;
}
.Toastify__toast-body {
  font-size: 14px;
}
.mosanic-icon {
  &.-small svg{
    margin-top: -2.5px;
    transform: scale(0.85);
    transform-origin: top center;
  } 
  :is(svg, svg path) {
    fill: currentColor;
  }
}

.builderContainer {
  min-width: 100vw;
  max-height: 100vh;
}




/** tree items **/ 

.Wrapper {
 
}




.disableInteraction {
  pointer-events: none;
}

.disableSelection,
.clone {
  .Text,
  .Count {
    user-select: none;
    -webkit-user-select: none;
  }
}

.Collapse {
  svg {
    transition: transform 250ms ease;
  }

  &.collapsed svg {
    transform: rotate(-90deg);
  }
}



// action 
$focused-outline-color: #4c9ffe;

.Action {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    &:hover {
      background-color: var(--action-background, rgba(0, 0, 0, 0.05));

      svg {
        fill: #6f7b88;
      }
    }
  }

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
  }

  &:active {
    background-color: var(--background, rgba(0, 0, 0, 0.05));

    svg {
      fill: var(--fill, #788491);
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0),
      0 0px 0px 2px $focused-outline-color;
  }
}

// .editor-scrollable {left:0!important;}
.monaco-editor .margin {display: none;}
.monaco-editor .margin, .monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
  background: transparent!important;
}