
.dropZone {

  border-radius: $border-radius-xs;
  margin: 0;
  border: 1px solid #6ab4f51d!important;
  transition: all ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s;
  flex: 0 0 auto;
  width: 100%;
  height: 25px;
  max-height: 25px;
  transition: 200ms all;
  // background: rgba(244, 244, 244, 0.101);
  opacity: 0;

  &.slot {
    // max-height: 10px;
    border-color: #5291c895;
  }
  span.label {
    position: absolute;
    color: #333;
    opacity: 1;
    font-size: 0.7em;
    line-height: 1.9em;
    -webkit-font-smoothing: antialiased;
  }
  &.isLast {
    // background: rgba(236, 236, 236, 0.101);
  }

  &.canDrop {
    z-index: 99!important;
    // background: red!important;
    transform: scale(0.9);
    // position: relative;

    // &.stack__dropZone {
    //   background: rgba(233, 16, 16, 0.156)!important;  
    //   border: 1.5px dashed rgba(239, 8, 8, 0.382)!important;
    // }
  }

  &.active {
    transform: scale(0.97);
    opacity: 0.4;
    // background: rgba(16, 132, 233, 0.43)!important;
    border: 1px solid $sky-blue!important;
    
    
  }
  &.isOver {
    background: blue!important;
  }

  // &:hover {
  //   opacity: 0.5;
  //   background: rgba(244, 244, 244, 0.2);
  // }

  &.-show {
    opacity: 1!important;
    display: block!important;
  }
}
.repeater {

  &.empty {
    min-height: 20px;
    margin: 5px 0;
    .dropZone.canDrop {
      &.repeater__dropZone {
        margin-top: 0;
      }
    }
  }
  .dropZone.canDrop {
    &.repeater__dropZone {
      // opacity: 0.3!;
      background: rgba(233, 157, 16, 0.074)!important;  
      border: 1.5px dashed rgba(239, 131, 8, 0.682)!important;
      &.active { 
        border-color: rgba(239, 139, 8, 0.782)!important;
      }
    }
  }
}
.stack {
  .dropZone.canDrop {
    &.stack__dropZone {
      background: rgba(233, 16, 16, 0.156)!important;  
      border: 1.5px solid rgba(239, 8, 8, 0.682)!important;
      &.active { 
        border-color: rgba(239, 8, 8, 0.782)!important;
      }
    }
  }
  .stack {
    .dropZone.canDrop {
      &.stack__dropZone {
        background: rgba(16, 236, 8, 0.356)!important;  
        border: 1.5px solid rgba(43, 214, 123, 0.582)!important;
        &.active { 
          border-color: rgba(43, 214, 123, 0.982)!important;
          transform: scale(1);
        }
      }
    }
    .stack {
      .dropZone.canDrop {
        &.stack__dropZone {
          background: rgba(175, 8, 236, 0.356)!important;  
          border: 1.5px solid rgba(185, 43, 214, 0.582)!important;
          &.active { 
            border-color: rgba(208, 43, 214, 0.982)!important;
            transform: scale(1);
          }
        }
      }
    }
  }
}

.dropZone:nth-of-type(2n) {
  // display: none;
  opacity: 0.1;
  // background: red;
}

//TODO check
.dropZoneWrapper {
  // &.-horizontal {
  //   width: 2%;
  //   .dropZone.horizontalDrag {
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     max-height: 100%;
  //     min-height: 100%;
  //   }
  // }
}
 .dropZone.horizontalDrag {
  //  position: absolute;
   width: 2%;
   height: auto;
   max-height: none;
   min-height: auto;
 }


.dropZone.isLast.page__dropZone {

}

//todo check why flex is set
.dropZone:not(.horizontalDrag).isLast {
  // flex: 1 1 auto;
}

.dropZone.active,
.trashDropZone.active {
  border: 1px solid $sky-blue;
  background: #00a2ff24;
  transition: 200ms all;
  opacity: 1;
}

.trashDropZone.active {
  transform: scale(0.9);
  border-radius: 10px;
}


.trashDropZone {
  transition: all ease-in-out 0.4s;
  transform: scale(0.85);
  opacity: 0.3;
  border-radius: 4px;
  font-size: 12px;
  position: relative;
  text-align: center;
  padding: 15px 25px;
  margin: 5px auto 0;
  width: 100px;
  height: 20px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}