// .builderWrapper {
  .component {

    // z-index: 2;
    // cursor: move;
    // height: 60px;
    // max-height: 150px;
    // transition: all ease-in-out 0.3s;
    // padding: 5px 10px;

    // &::after {
    //   border: 0.5px dashed $gray-310;
    // }
    // .inspectorLive {
    //   border: 0.5px dashed $gray-310;
    // }
    // .inspectElement.-component .-border {
    //   border: .5px dotted $gray-310;
    // }

    // overflow: hidden;

    &.-text {
      overflow: visible;
    }

    &.-hover {
      cursor: pointer;
      // opacity: 0.4;
    }

    .component__label {
      // position: absolute;
      // left: 0;
      // top: -40px;
      // margin: 0!important;
      // z-index: 999;

      // max-height: 37px;
      // overflow: hidden;
      // padding: 5px;
      // // background: $gray-120;
      // background: red;
      // font-size: 0.7em;
      // transition: all ease-in-out 0.3s;
      


      // .component__controls {
      //   margin: -7px 2px;
        
      //   .component__controlsBtn {

      //   }
      //   .editor__toggle {
      //     svg {
      //       fill: $storm;
      //     }
      //     svg:hover {
      //       fill: $sky-blue;
      //     }
      //   }
      // }
      
      // button.MuiButton-root {
      //   margin: 0;
      //   min-width: 20px;
      //   transform: scale(0.7);
      // }
      
      &.-fly {
        // transition: all ease-in 0.2s;
        opacity: 0;
        margin-top: -50px;
        // max-height: 50px;
        max-height: 0px;
        position: absolute;
        // opacity: 1;
        z-index: 3;
        width: 200px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 6px 0 rgba(0,0,0,.13);
        padding: 10px 16px;
        font-size: 14px;
        // margin: 10px;
        font-weight: lighter;

        &.-sky {
          margin: 10px;
          box-shadow: 6px 6px 18px 0 rgba(0,0,0,.1);
        }
      }

    }
    // &:hover {
    //   cursor: pointer;
    //   border: .5px solid $sky-blue!important;

    //   .component__label.-fly {
    //     // opacity: 1;
    //   }
    // }
    &:active::after, &:focus::after {
      cursor: move;
      // border: .5px solid red!important;

      .component__label.-fly {
        // opacity: 1;
      }
    }
    // &.-active{
    //     border: 1.5px solid $sky-blue;

    //     .component__label.-fly {
    //       opacity: 1!important;
    //       max-height: 50px!important;
    //     }
    // }
  }
// }

// .inspector__menu {

//   &.-hide {
//     opacity: 0;
//   }
//   &.-mini {
//     .inspector__container {
//       padding-right: 5px;
//       left: 0px;
//       max-width: 70px;
//       min-width: 70px;
      
//       .icon svg {
//         transform: scale(1.2);
//         margin-right: 5px;
//       }
//       .label{
//         display: none;
//       }
//       &.-editing {
//         .icon svg {
//           margin-right: 0;
//           margin-left: 5px;
//         }
//       }
//     }
//     &.-label .inspector__container {
//       left: 8px;
//       max-width: 25px;
//       min-width: 25px;
//     }
//   }
//   &.-small {
//     .inspector__container {
//       padding-right: 5px;
//       max-width: 120px;
//       min-width: 120px;
//     }
//   }
//   &.-small:not(.-label) .inspector__container {
//     left: 0px;
//   }
//   &.-radiusBottom .inspector__container{
//     border-bottom-right-radius: 5px;
//   }
// }


.inspector__menu.-label {
  position: absolute;

  .inspector__container {
    width: 90px;
    min-width: 0;
    // transform: scale(0.9);
    // transform-origin: bottom left;
  }
}
.inspector__menu .inspector__container {
      // background: $gray-120;



      @keyframes smoothAttention {
        0% {
          transform: scale(0.9);
          border: 2px solid rgba(244, 25, 5, 0.1);;
        }
        50% {
          transform: scale(1.2);
          border: 2px solid #f3efef50;
        }
        100% {
          transform: scale(0.9);
          border: 2px solid rgba(244, 25, 5, 0.1);
        }

      
      }
      @keyframes smoothSpin {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(10deg);;
        }
        50% {
          transform: rotate(-10deg);;
        }
        100% {
          transform: rotate(0deg);
        }
      }
      .variantsContainer {
    
        
      }
      .variations,
      .variationsStop {
         background: #fff;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.101);
        max-height: 35px;
      }

      .indicators {
        margin-top: 5px ;
        transition: all ease-in-out 0.3s;
        width: 0; 
        height: 15px;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        background: $gray-110;
        border: 1px solid $gray-210;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &.-visible {
          width: 15px; 

          .indicator {
            opacity: 1;
          }
        }

        .indicator {
          opacity: 0;
          transition: all ease-in-out 0.4s;
          margin: 5px;
          width: 5px;
          height: 5px;
          content: '';
          display: block;
          background: #d60c0c;
          border: 0.5px solid #e0131371;
          border-radius: 50%;
          animation: smoothAttention 1s infinite linear;
        }
      }
      .variationsStop {
        // top: 4px;
        float: left;
        margin-top: 2.5px;
        margin-left: 10px;
        width: 27px;
        height: 27px;
        display: block;
        content: '';
        left: auto!important;
        border-radius: 50%;
        transition: all ease-in-out 0.25s;
          border: 2px solid #f4190500;
          
        &.-screen {
          border: 1px solid lighten($sky-blue, 15%) !important;
        }
        &.-animated {
         animation: smoothAttention 1s infinite linear;
        }

         svg {
           margin:4px;
         }
         &:hover {
           box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.074);
           border: 2px solid rgba(244, 25, 5, 0.115) !important;
          // animation-play-state: paused;
          //  transform: scale(1.3)!important;
          //  background:rgba(246, 222, 222, 0.533);

           svg{
             animation: smoothSpin 2.5s infinite linear;
            fill: #f41905!important;
            color: #f41905!important;
          }
        }
      }
      .inspector__label {
        margin: -5px 0;
      }
      
        &.-editing {
          // overflow-y: hidden;
          cursor: pointer;
          width: 90px;
          min-width: 0;
          padding-left: 5px;

          &::after {
            position: absolute;
            display: block;
            content:'';
            background: linear-gradient(90deg, #1084e900 0%, #1082e9 70%);
            top: 0;
            bottom: 0;
            right: 0;
            width: 30px;
            border-top-right-radius: 5px;
          }
          .controls {
            position: absolute;
            opacity: 0;
          }

          .closeEditor {
            z-index: 3;
            transition: all ease-in-out 0.3s;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 0;
            top: 0px;
          }

          &:hover {
            width: 85px;
            .closeEditor {
              margin-top: -1.5px;
              transform: scale(1.4);
              transform-origin: center center;
            }
          }
        }
      
      
      button.MuiButton-root {
        margin: 0;
        min-width: 20px;
        transform: scale(0.7);
      }
    }