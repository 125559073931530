
.builderWrapper {
  .draggable.stack.-stack {
    max-width: none !important;
    margin: 0;
  }
  &.-isDragging {
    .d-flex {
      .dropZone {
        // transition: none;
        position: relative;
      }
    }

  }
  .d-flex {
    .dropZone  {
      transition: none;
      position: absolute;
      max-height: 25px;
    } 
    .dropZone.isLast {
      z-index: 9!important;
    }
    &.column-dir .dropZone{
      //  flex-basis:100%; 
       max-height: 25px;
      //  position: absolute;
    }
     &.row-dir:not(.-wrap) .dropZone{
      //  flex-basis:100%; 
       max-width: 25px!important;
       width: 25px;
       max-height: 100%;
       height: 100%!important;
       min-height: 100%;
       margin: 0 -12.5px;
       content: '';
      //  position: absolute;
    }
    &.-wrap {

    }
    // flex-wrap: wrap !important;
  }
   .column,
   .stack {

  
    // padding: 10px;
    position: relative;

    &.-hover {
      cursor: pointer;
      // opacity: 0.4;
    }
  
  }
  .column__flex,
  .stack__flex,
  .repeater__flex {
    height: 100%!important;
    width: 100%!important;
  }
  &:not(.-preview, .-dndDisabled){
    .stack__flex,
    .column__flex,
    .repeater__flex {
      &.row-dir {
        .dropZone {
          width: 100%;
          min-width: 10px;
          // height: 100%;
          position: absolute;
          opacity: 0;
        }
      }
      &.column-dir {
        .dropZone {
          width: 100%;
          height: 25px;
        }
      }
      .dropZone {
        // transition: width ease 0s;
        // transition: height ease 0s;
      }
      &.d-flex.hide-label {
        .-devLabel,
        .dropZone {
          // display: none;
        }
      }
    }
  }
  &.-isDragging{
    .stack__flex,
    .column__flex,
    .repeater__flex {
      &.row-dir .dropZone.canDrop {
        // height: 100%;
        max-height: none;
        // min-height:100%;
        // align-items: stretch;
        // top: 0;
        // bottom: 0;
        // min-height: none;
        // position: relative!important; //todo fix --> flex box dropboxing
        opacity: 1;
      }
    }
  }
  
  
}
.coreWrapper .column {position: relative;}