
.core__header {
  z-index: 4;
  width: 100%;
  position: fixed;
  padding: 0 10px;
  background: #fff;
  border: 1px solid rgb(236, 236, 236);
  box-shadow: 0 1px 5px 0 rgba(46, 72, 90, 0.21);
  border-bottom: 0.25px solid #e5e5e5!important;

  &.navigationHeader {
    box-shadow: none;
    z-index: 5;
  }

  .header__iconBar {
    padding-left: 10px;
    border-left: 1px solid #e5e5e5;
    display: inline-block;

    &:first-child {
      border-left-color: rgba(0, 0, 0, 0);
      // padding-left:
    }
    &.-noBorder {
      border-left-color: rgba(0, 0, 0, 0); 
    }


    &.-center {
      position: absolute;
      left: calc(50% - 125px);
      width: 250px;
      margin: 0 auto;
    }
    &.-right {
      float:right;
    }
  }
  .header__icon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    transition: all ease-in-out 0.3s;
    cursor: pointer;

    &.-custom {
      padding: 5px;
    }

    &:hover {
      svg, img {
        transform: scale(0.9);
      }
    }
    svg,
    img {
      transform: scale(0.85);
      margin: 7px;
      transition: all ease-in-out 0.3s;
    }
    &.-stroke svg,
    &.-stroke img {
      stroke: $gray-710;
    }

    &::after {
      transition: all ease-in-out 0.3s;
      content: " ";
      position: absolute;
      height: 2px;
      width: 40px;
      bottom: 0;
      left: 0;
    }
    &.-selected {
      svg,
      img {
        fill: $green-shamrock;
        color: $green-shamrock;
      }

      &.-line::before {
        background-color: $green-shamrock;
      }
      &::after {
        background-color: $green-shamrock;
      }
      &.-blue {
        svg,
        img {
          fill: $sky-blue;
          color: $sky-blue;
        }  
        &::after {
          background-color: $sky-blue;
        }
      }
    }
    &.-scale {
      transition-duration: 0s;
      margin-left: -5px;
      transform: scale(0.8);

      &:hover {
        transform: scale(0.95);

        &::after {
          height: 0 !important;
          background-color: none!important;
        }
      }
      &:active {
        transform: scale(0.8);
      }
    }
    &.-stroke.-selected {
      svg,
      img {
        stroke: $sky-blue;
      }
      &::after {
        background-color: $sky-blue;
      }
    }
    &.-flip {
      transform: rotateY(-180deg);
    }
    &.-xs {
      max-width: 10px;
      color: rgba(0,0,0,0);
      padding-left: 10px;
      margin: -17px 0 0;
      margin-left: 0;
      margin-right:0;
    }
    &.-line,
    &.-line:hover {
      width: 20px;
      &::before {
        content: " ";
        position: absolute;
        width: 2px;
        height: 20px;
        top: 25px;
        left: 0;
        bottom: 0;

        background: $gray-210;
      }

      &::after {
        bottom: -17px!important;
        width: 20px!important;
        left: -10px!important;
      }
    }
    
    &.-text {
      padding-top: 10px;
      font-size: 12px;
      font-weight: bold;
    }


    //Hover icons
    &:hover:not(.-selected) {
      &:not(.-stroke) svg {
        fill: $storm;
        color: $storm;
      }
      &.-stroke svg,
      &.-stroke img {
        stroke: $storm;
      }
      &::after {
        content: " ";
        position: absolute;
        height: 2px;
        width: 40px;
        background-color: $storm;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.mainCaps {
  width: 25%;
  float: left;
  .label {
    text-transform: capitalize;
  }
  
  &:nth-child(even){
    background: $gray-120;
  }
  .subCaps {
    border-bottom: 1px solid $storm;

    &:last-child {
      border-bottom: 0;
    }
    // float: left;
  }
}