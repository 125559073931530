
.control__panelContainer {
  z-index: 99;
}
.panel__trigger {
    // margin: 20px;
    cursor: pointer;
    transition: all ease-in-out 0.3s; 

    &:hover {
        transform: scale(1.2)!important;
    }


}
.control__panel {
    // z-index: 5;
    opacity: 1;
    z-index: 5;
    width: 200px;
    box-shadow: 0 1px 8px 0 rgba(0,0,0,.13);

    &.-bigShadow {
        box-shadow:
            0 -5px 13px rgba(0, 0, 0, 0.048),

            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086)
            // 0 100px 80px rgba(0, 0, 0, 0.12)
        ;
    }

    // padding: 16px;
    font-size: 14px;
    // margin: 10px;
    // font-weight: lighter;
    // margin: 5px 10px 10px 5px;

    
    .panel__header {
        font-size: 14px;
        
        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    &.-fly {
        margin: 10px;
        box-shadow: 4px 4px 30px 10px rgba(0,0,0,.15);
    }
   
    &.-large {
        width: 250px;
    }
    &.-xl {
        width: 375px;
    }
    &.-offset {
        margin: 5px 15px;
    }
    &.-scaleDown {
        transform-origin: center top;
        transform: scale(0.9);
    }

    &.-scroll {
        width: 150px;
        height: 200px;
        overflow-y: scroll;
    }

    .MuiButtonGroup-root {
        transform-origin: left;
        transform: scale(0.9);
        margin: 0 0 -10px 0;
        width: 100%;
        svg {
            width: 20px;
        }
    }
    .ButtonGroup__container {
        width: 120%;
        //todo check width overflow
    }
}
