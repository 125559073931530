$white: #fff;
$ghost: #fdfdff;
$alabaster: #fbfbfb;
$smoke: #f8f8f8;
$gray-110: #fcfdfe;
$gray-120: #f4f7fa;
$gray-210: #e7e9ed;
$gray-opacity: rgba(231, 233, 237, 0.13);
$gray-310: #d5d7dd;
$storm: #7d818d;
$gray-610: #666666;
$gray-710: #413e65;
$gray-910: #1f1f1f;
$gray-920: #1e1e20;
$gray-930: #19191b;
$gray-940: #161c2d;
$black: #000;

$red: #f64b4b;
$blue: #1082e9;; 
$dark-blue: #0a3f6c;
$sky-blue: #1082e9;
$light-blue: #c3daff;
$green: #68d585;
$purple-shamrock: #f810e5;
$red-shamrock: #f41d1d;
$green-shamrock: #2bd67b;
$blackish-blue: #13151c;
$blackish-blue-opacity: rgba(#161c2d, 0.7);
$mirage: #131829;
$mirage-2: #161c2d;
$yellow: #f7e36d;
$yellow-orange: #fcad38;
$narvik: #edf9f2;

$primary: $blue;
$secondary: $green;
